<script setup>
import {ref, onBeforeMount} from "vue"
import {useRoute, useRouter} from "vue-router"
import request from "../request";

const route = useRoute()
const router = useRouter()

const parts = useRoute().path.split('/')
const type = parts[1]

const loading = ref(false)
const success = ref(null)

const state = route.query.state
const code = route.query.code

if (!state) {
	loading.value = false
	success.value = false
}

const addIntegration = () => {
	loading.value = true
	request.post(process.env.VUE_APP_BASE_URL + `/${type}/webhook`, {
		state,
		code
	})
		.then(data => {
			loading.value = false
			success.value = true
			setTimeout(() => {
				router.push({
					name: 'ServerDetails',
					params: {
						project_id: data.project_id,
						server_id: data.server_id
					}
				})
			}, 400)
		})
		.catch(error => {
			loading.value = false
			success.value = false
			console.log(error)
		})
}

const backToHomepage = () => {
	router.push({
		name: 'Dashboard'
	})
}

onBeforeMount(() => {
	if (state) {
		addIntegration()
	}
})

</script>

<template>
	<div class="d-flex justify-content-center align-items-start vh-100">
		<div class="text-center">
			<i :class="`bi bi-${type}`"></i>
			<div v-if="loading" class="loading">
				<var-loading type="wave" size="large" />
				<h1>Adding integration...</h1>
			</div>
			<div v-if="success === true">
				<var-icon name="checkbox-marked-circle" size="64" color="var(--color-success)" />
				<h1>Integration added successfully.</h1>
			</div>
			<div v-else-if="success === false">
				<var-icon name="error" size="64" color="#ff0000" />
				<h1>Failed to add integration, please try again later.</h1>
				<var-button type="primary" @click="backToHomepage">Back to dashboard</var-button>
			</div>
		</div>
	</div>
</template>

<style scoped>

.bi {
	font-size: 18rem;
}

</style>